import _ from "lodash";
import {
  BaseHeader,
  Channel,
  Data,
  DataTransformed,
  ExtendedFinancialItem,
  InitialInformationForAccordion,
  ItineraryTableHeaders,
  QuoteInspectorData,
  Rent,
  TableDefinitionBase,
  TrackingChanges,
} from "./BaseQuoteInspect";
import { format } from "date-fns";

let decimals = 3;
let availability_rate = 0;
let min_rate = 0;
let night_rate = 0;
let dynamic_discounts = 0;
let disc = 0;
let segments = [];

export function dataTransformation(data, channel: string) {
  availability_rate = 0;
  min_rate = 0;
  night_rate = 0;
  dynamic_discounts = 0;
  disc = 0;
  const newData: DataTransformed = {
    data: [],
    notBookableReason: null,
  };
  if (data.length > 0) {
    data.forEach((e, i) => {
      if (_.isNull(e.not_bookable_reason)) {
        decimals =
          e?.currency &&
          (e?.currency?.decimals === 0 || e?.currency?.decimals > 3)
            ? 3
            : e.currency.decimals;
        segments = [];
        newData.data.push({
          id: i + e.updated_at ?? "",
          channel,
          initialInformation: preparateInitialInformation(e),
          labels: getLabels(e, channel),
          notBookableReason: null,
          itineraryTable: {
            id: i + "-itinerary-table",
            data: preparateRowsAndHeadersForItineraryTable(e),
          } as TableDefinitionBase,
          tabs: {
            rent: {
              id: e.updated_at + "-rent",
              data: preparateRowsAndHeadersForRentTable(e),
              segments,
            },
            fees: {
              id: e.updated_at + "-fees",
            },
            taxes: {
              id: e.updated_at + "-taxes",
            },
          } as TrackingChanges,
          fees: getFees(e.financial.fees),
          taxes: getTaxes(e.financial.taxes),
          totals: [
            [
              "Total Value",
              round(availability_rate),
              round(min_rate),
              round(night_rate),
              round(dynamic_discounts),
              "N/A",
              round(disc),
            ],
          ],
          unit: e.unit,
        } as QuoteInspectorData);
      } else {
        newData.data.push({
          id: e.updated_at,
          notBookableReason: e.not_bookable_reason,
          unit: {
            ids: {
              admin: e.unit?.ids.admin,
            },
          },
          reservation_scenario_id: e.reservation_scenario_id,
          initialInformation: {
            date: format(
              new Date(e.updated_at),
              "yyyy-MM-dd kk:mm:ss zzzz"
            ).toString(),
            values: null,
            description: null,
          },
          channel: e.channel,
        });
      }
    });
  } else {
    newData.notBookableReason = "No Data";
  }
  return newData;
}

function preparateInitialInformation(element): InitialInformationForAccordion {
  return {
    values: getRent(
      element.stay.combination.rate,
      element.financial.fees,
      element.financial.taxes,
      element.currency.code
    ),
    description: "",
    date: format(
      new Date(element.updated_at),
      "yyyy-MM-dd kk:mm:ss zzzz"
    ).toString(),
  } as InitialInformationForAccordion;
}

function getRent(rates, fees, taxes, currency): Rent {
  let totalFee = 0;
  let totalTax = 0;
  fees.forEach((fee) => {
    totalFee += fee.evaluatedValue;
  });
  taxes.forEach((tax) => {
    totalTax += tax.evaluatedValue;
  });
  return {
    rent: round(sum(rates)),
    taxes: round(totalTax),
    fees: round(totalFee),
    currency,
  } as Rent;
}

function preparateHeadersForItineraryTable(availability): BaseHeader[] {
  const headers: BaseHeader[] = [];
  Object.entries(ItineraryTableHeaders).forEach((header) => {
    headers.push({
      id: header[0].toLowerCase(),
      label: header[1].toLowerCase(),
      details:
        header[1] === ItineraryTableHeaders.AVAILABILITY_RATE
          ? availability
          : undefined,
      message: {
        explication:
          header[1] === ItineraryTableHeaders.DYNAMIC_DISCOUNTS
            ? "IBP Enabled: Long term discount + Calendar Block. IBP Disable: Long term discount"
            : undefined,
      },
    } as BaseHeader);
  });
  return headers;
}

function preparateRowsAndHeadersForItineraryTable(element): Data {
  const rows: { [key: string]: any }[] = element.availabilities.map(
    (availability, index) => {
      return {
        date: availability.date,
        availability_rate: round(
          element.stay.combination.availability_rate[index]
        ),
        min_rate: round(element.stay.combination.min_rate[index]),
        night_rate: round(element.stay.combination.rate[index]),
        dynamic_discounts: round(element.stay.combination.ltd[index]),
        pob: round(element.occupancy_probabilities[availability.date]),
        disc: round(element.stay.combination.disc[index]),
      };
    }
  );
  preparateTotalValuesForTable(rows);
  return {
    headers: preparateHeadersForItineraryTable(element.availabilities),
    rows,
  };
}

function preparateTotalValuesForTable(rows: { [key: string]: any }[]) {
  rows.forEach((row) => {
    availability_rate += row.availability_rate;
    min_rate += row.min_rate;
    night_rate += row.night_rate;
    dynamic_discounts += row.dynamic_discounts;
    disc += row.disc;
  });
}

function preparateRowsAndHeadersForRentTable(element): Data {
  const headers: BaseHeader[] = [
    { id: "date", label: "date" },
    { id: "initial", label: "initial" },
  ];
  const rows: { [key: string]: any }[] = [];
  element.availabilities.forEach((availability) => {
    rows.push({ date: availability?.date, initial: availability?.rate });
  });
  element.transformation_history.forEach((transformation, index) => {
    segments.push(transformation.segmentId);
    if (
      _.isEqual(
        transformation.beforeApply.rent,
        transformation.afterApply.data.rent
      )
    ) {
      return;
    }
    headers.push({
      id: transformation.segmentId,
      label: transformation.segmentName,
      priority: index,
    } as BaseHeader);
    Object.values(transformation.afterApply?.data?.rent).forEach(
      (rent, indexRow) => {
        _.set(rows[indexRow], headers[headers.length - 1].id, round(rent));
      }
    );
  });
  return { headers, rows };
}

function getLabels(element, channel: string): BaseHeader[] {
  let labels: BaseHeader[] = [];
  labels.push({
    id: "Exchange Rate : ",
    label: element.channel_unit_configuration["currency_exchange_rate"],
    message: null,
  });
  if (channel === Channel.BOOKING) {
    labels.push({
      id: "Inventory : ",
      label: element.stay.combination.inventory.toString(),
      message: {
        title: "Explication",
        explication:
          "For BDC Clusters, states how many units under the representative unit are available",
        link: null,
      },
    });
  }
  if (channel === Channel.AIRBNB) {
    labels.push({
      id: "Tax Scenaries : ",
      label: element.channel_unit_configuration["tax_collection_scenario"],
      message: {
        title: "Explication",
        explication: "For more information, you can visit the confluence page ",
        link: "https://vacasait.atlassian.net/wiki/spaces/ENG/pages/2584477921/Airbnb+Taxes+scenarios",
      },
    });
  }
  if (element.stay.combination["flexible_min_stay"]) {
    labels.push({
      id: "Flexible min stay : ",
      label: element.stay.combination["flexible_min_stay"].toString(),
      message: null,
    });
  }
  return labels;
}

function getFees(fees: ExtendedFinancialItem[]): ExtendedFinancialItem[] {
  const processedFees = [...fees];
  fees.forEach((fee, index) => {
    processedFees[index] = {
      ...fee,
      evaluatedValue: round(fee.evaluatedValue),
      conditions: [],
    };
  });
  processedFees.forEach((fee) => {
    fee.targets.forEach((target) => {
      setConditions(target, fee);
    });
  });
  return processedFees;
}

function getTaxes(taxes: ExtendedFinancialItem[]): ExtendedFinancialItem[] {
  const processedTaxes = [...taxes];
  taxes.forEach((tax, index) => {
    processedTaxes[index] = {
      ...tax,
      evaluatedValue: round(tax.evaluatedValue),
      conditions: [],
    };
  });
  processedTaxes.forEach((tax) => {
    if (tax.hasOwnProperty("fees")) {
      const item = tax.conditions.find(
        (condition) => condition.name === "fees"
      );
      const toAdd = tax.fees.join(" , ");
      setCondition(tax.conditions, item, toAdd, "fees");
    }
    tax.targets.forEach((target) => {
      setConditions(target, tax);
    });
  });

  return processedTaxes;
}

function setConditions(target, applyTo) {
  const setConditionHelper = (name, value, type) => {
    const item = applyTo.conditions.find(
      (condition) => condition.name === name
    );
    let toAdd = "";
    if (type === "min" || type === "max") {
      const currentCurrency = value.currency;
      toAdd = `${value.value} ${
        currentCurrency ?? value.type === "nights" ? value.type : ""
      }`;
    } else if (type === "appliesTo") {
      toAdd = target[type].join(" , ");
    } else if (type === "condition") {
      toAdd = `Apply if ${value?.type} is ${value.qualifier.type} ${value.qualifier.value}`;
    } else if (type === "rules") {
      if (value.qualifier) {
        const condition =
          value.adjustment.currency == null &&
          Math.abs(value.adjustment.value) < 1;
        const currentCurrency = value.adjustment.currency;
        toAdd = `Apply ${
          condition
            ? formatAsPercent(100 * value.adjustment.value)
            : value.adjustment.value
        } ${currentCurrency ?? ""} if ${value.type} is ${
          value.qualifier.type
        } ${value.qualifier.value}`;
      } else {
        toAdd = `Apply ${value.adjustment.value} ${value.adjustment.currency} ${value.type}`;
      }
    }
    setCondition(applyTo.conditions, item, toAdd, name);
  };

  if (target.min != null) {
    setConditionHelper("min", target.min, "min");
  }
  if (target.max != null) {
    setConditionHelper("max", target.max, "max");
  }
  if (target.appliesTo != null) {
    setConditionHelper("appliesTo", target.appliesTo, "appliesTo");
  }
  if (target.condition != null) {
    if (Array.isArray(target.condition)) {
      target.condition.forEach((condition) =>
        setConditionHelper("condition", condition, "condition")
      );
    } else {
      setConditionHelper("condition", target.condition, "condition");
    }
  }
  if (target.rules != null) {
    target.rules.forEach((rule) => setConditionHelper("rules", rule, "rules"));
  }
}

function setCondition(condition, item, toAdd, name) {
  if (item) {
    item.values.push(toAdd);
  } else {
    condition.push({ name, values: [toAdd] });
  }
}

export function round(num): number {
  return Math.round((num + Number.EPSILON) * (10 * decimals)) / (10 * decimals);
}

export function formatAsPercent(num) {
  return new Intl.NumberFormat("default", {
    style: "percent",
    minimumFractionDigits: 0,
    maximumFractionDigits: 2,
  }).format(num / 100);
}

export function getCapitalizeString(data: string): string {
  let newData = "";
  data
    .replaceAll(" ", "-")
    .replaceAll("_", "-")
    .split("-")
    .forEach((word) => {
      newData += word.charAt(0).toUpperCase() + word.substring(1) + " ";
    });
  return newData;
}

export function urlBuilder(basePath: string, queryParameters?: any): string {
  if (!queryParameters || Object.keys(queryParameters).length === 0) {
    return basePath;
  }
  Object.keys(queryParameters).forEach((q) => {
    if (_.isNil(queryParameters[q])) {
      queryParameters = _.omit(queryParameters, q);
    }
  });
  return `${basePath}?${Object.keys(queryParameters)
    .map((q) => `${q}=${queryParameters[q]}`)
    .join("&")}`;
}

export function sum(arr: number[]): number {
  return arr.reduce((acc, cur) => (acc += cur), 0);
}
